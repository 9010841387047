import MeetingBase from "../../MeetingBase";
import ChatPanel from "./ChatPanel";
import ParticipatsPanel from "./ParticipatsPanel";
import MeetingHandler from "../../MeetingHandler";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShareLinkSection from "./ShareLinkSection";
import { isMobileOnly } from "react-device-detect";
import { ArrowForward } from "@material-ui/icons";
class RightPanel extends MeetingBase {
  constructor() {
    super();
    this.state = {
      showDiv: false,
      showMessages: false,
      shouldShowMessages: true,
      participants: [],
      messages: [],
    };
    this.onMsgTabTapped = this.onMsgTabTapped.bind(this);
    this.onParticipantsTapped = this.onParticipantsTapped.bind(this);
    this.onNewMessage = this.onNewMessage.bind(this);
    this.onUserJoined = this.onUserJoined.bind(this);
    this.onParticipants = this.onParticipants.bind(this);
    this.onUserLeft = this.onUserLeft.bind(this);
    this.onTapSwitch = this.onTapSwitch.bind(this);
  }

  componentDidMount() {
    super.addExtraListenerWithForcefullyAdded(
      "onNewChatMessageReceived",
      this.onNewMessage,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "onUserJoined",
      this.onUserJoined,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "onUserLeft",
      this.onUserLeft,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "participants",
      this.onParticipants,
      true
    );
    MeetingHandler.getInstance().subscribeToLocalEvent(
      "onTapSwitch",
      this.onTapSwitch
    );

    super.getMeetingHandler().getParticipants();
  }

  componentWillUnmount() {
    super.removeExtraListener("onNewChatMessageReceived", this.onNewMessage);
    super.removeExtraListener("onUserJoined", this.onUserJoined);
    super.removeExtraListener("onUserLeft", this.onUserLeft);
    super.removeExtraListener("participants", this.onParticipants);
    MeetingHandler.getInstance().unSubscribeToLocalEvent(
      "onTapSwitch",
      this.onTapSwitch
    );
  }

  onNewMessage(messagePayload) {
    console.log(messagePayload);
    MeetingHandler.getInstance().addNewChatMessage(messagePayload);
    this.setState({ messages: MeetingHandler.getInstance().getChatMessages() });
  }

  onParticipants(data) {
    MeetingHandler.getInstance().onAllParticipants(data);
  }

  onTapSwitch(data) {
    if (data.isVideoTab) {
      this.setState({ showMessages: false });
    } else {
      this.setState({ showMessages: true });
    }
  }

  onUserJoined(newUser) {
    MeetingHandler.getInstance().onNewParticipantAdded(newUser);
    this.setState({
      participants: MeetingHandler.getInstance().getParticipants(),
    });
    let newUserNme = newUser.userData.name;
    toast(newUserNme + " joined", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }

  onUserLeft(user) {
    MeetingHandler.getInstance().onParticipantLeft(user);

    this.setState({
      participants: MeetingHandler.getInstance().getParticipants(),
    });

    let userName = user.userData.name;
    toast(userName + " left", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }

  onMsgTabTapped() {
    this.setState({ shouldShowMessages: true });
  }
  onParticipantsTapped() {
    this.setState({ shouldShowMessages: false });
  }

  render() {
    return (
      <div
        className="col-md-12"
        style={{
          display: `${
            this.state.showMessages || !isMobileOnly ? "block" : "none"
          }`,
          padding: "0px",
        }}
      >
        <div className="video_chat">
          <ShareLinkSection />

          <div className="mngroup">
            {/* Nav tabs */}
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  onClick={this.onMsgTabTapped}
                  className={
                    this.state.shouldShowMessages
                      ? "nav-link  active"
                      : "nav-link"
                  }
                  data-toggle="tab"
                >
                  Message
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  onClick={this.onParticipantsTapped}
                  className={
                    !this.state.shouldShowMessages
                      ? "nav-link  active"
                      : "nav-link"
                  }
                  data-toggle="tab"
                >
                  Participants
                </a>
              </li>
            </ul>
            {/* Tab panes */}
            <div className="tab-content ">
              {this.state.shouldShowMessages ? (
                <ChatPanel messages={this.state.messages} />
              ) : (
                <ParticipatsPanel
                  key={this.state.participants.length}
                  participants={this.state.participants}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RightPanel;
