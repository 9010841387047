import {Decoder, Encoder, tools, Reader} from 'ts-ebml';


class RecordingUtilies{
    
    static instance = null;
     

    constructor(){
       this.mediaRecorder = null;
       this.audioContext = new AudioContext();
       this.audioDestination = this.audioContext.createMediaStreamDestination();
       this.recordingCanvas = null;
       this.recordedChunks = [];
       this.audioTracksWithNode = new Map();
        this.completionHandler = null;
       this.ondataavailable = this.ondataavailable.bind(this)
       this.fileName = "Recording"
    }
    

    static getInstance(){
        if(RecordingUtilies.instance === null){
            RecordingUtilies.instance = new RecordingUtilies();
        }
        return RecordingUtilies.instance;
    }
    
    startRecording(canvas){
        
        if(this.mediaRecorder === null){
            this.recordingCanvas = canvas;
            var options = { mimeType: "video/webm; codecs=vp8,opus" };
            let canvasMediaStream  = canvas.captureStream(30);
            let audioMediaStream = this.audioDestination.stream;

            if(audioMediaStream && audioMediaStream.getAudioTracks().length > 0)
            {
                canvasMediaStream.addTrack(audioMediaStream.getAudioTracks()[0]);
            }
            this.mediaRecorder = new MediaRecorder(canvasMediaStream, options);
            this.mediaRecorder.ondataavailable = this.ondataavailable;
            this.mediaRecorder.start(1200);

        }
    }

    addAudioTrack(audioMediaTrack){
        if(audioMediaTrack !== null && audioMediaTrack.track !== null && audioMediaTrack.participant !== null && audioMediaTrack.participant.userId !== null){
            this.removeOldTrackIfExist(audioMediaTrack);
            let audioMediaStream = new MediaStream([audioMediaTrack.track]);
        
            if(audioMediaStream && audioMediaStream.getAudioTracks().length > 0){
                let mediaStreamSource = this.audioContext.createMediaStreamSource(audioMediaStream);
                mediaStreamSource.connect(this.audioDestination)
                this.audioTracksWithNode.set(audioMediaTrack.participant.userId ,{"node" : mediaStreamSource, "trackObj" : audioMediaTrack})
            }
        }
    }
    removeOldTrackIfExist(audioMediaTrack){
        if(this.audioTracksWithNode.has(audioMediaTrack.participant.userId )){
            let nodeData = this.audioTracksWithNode.get(audioMediaTrack.participant.userId )
            if(nodeData.node){
                nodeData.node.disconnect(this.audioDestination)
            }
            this.audioTracksWithNode.delete(audioMediaTrack.participant.userId )

        }
    }

    stopRecording(completionHandler){
        if(this.mediaRecorder !== null){
            this.completionHandler = completionHandler;
            this.mediaRecorder.stop();
            this.mediaRecorder = null;
            this.recordingCanvas = null;
            this.download();
            
            RecordingUtilies.instance = null;

        }
        else{
            completionHandler();
        }
    }

    ondataavailable(event){
        // console.log("data-available");
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        } 
    }

     download() {
        // var blob = new Blob(this.recordedChunks, {
        //   type: "video/webm"
        // });
        // var url = URL.createObjectURL(blob);
        // var a = document.createElement("a");
        // document.body.appendChild(a);
        // a.style = "display: none";
        // a.href = url;
        // a.download = this.fileName + ".webm";
        // a.click();
        // window.URL.revokeObjectURL(url);
        // this.recordedChunks = [];
        if(this.recordedChunks.length > 0){
            let blob = new Blob(this.recordedChunks)
            if(blob ){
                this.injectMetadata(blob);

            }
        }

      }
      
 
      readAsArrayBuffer(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onloadend = () => { resolve(reader.result); };
            reader.onerror = (ev) => { reject(ev.error); };
        });
    }

    injectMetadata(blob) {
        const decoder = new Decoder();
        const reader = new Reader();
        reader.logging = false;
        reader.drop_default_duration = false;
    
        // load webm blob and inject metadata
        this.readAsArrayBuffer(blob).then((buffer) => {
            const elms = decoder.decode(buffer);
            elms.forEach((elm) => { reader.read(elm); });
            reader.stop();
    
            let refinedMetadataBuf = tools.makeMetadataSeekable(
                reader.metadatas, reader.duration, reader.cues);
            let body = buffer.slice(reader.metadataSize);
            let result = new Blob([refinedMetadataBuf, body],
                {type: blob.type});
    
            blob = result;
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = this.fileName + ".webm";
            a.click();
            window.URL.revokeObjectURL(url);
            this.recordedChunks = [];
            // the blob object contains the recorded data that
            // can be downloaded by the user, stored on server etc.
            if(this.completionHandler !== null){
                this.completionHandler();
                this.completionHandler = null;
            }
            console.log('finished recording:', blob);
        });
    }
    

}



export default RecordingUtilies;