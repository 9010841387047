import meetingHandler from 'com.vanimeeting.demo'
import Config from './extra/Config';
import Utility from './extra/Utility';
const EventEmitter = require('events')

class MeetingHandler{
    constructor(){
        this.meetingHandler = meetingHandler;
        this.meetingRequest = null;
        this.chatMessages = [];
        this.participants = [];
        // this.audioPauseResumeData = {};
        // this.videoPauseResumeData = {};
        this.eventEmitter = new EventEmitter()

    }

  
    getParticipants(){
        return this.participants;
    }

    getChatMessages(){
        return this.chatMessages;
    }

    addNewChatMessage(message){
        this.chatMessages.push(message);
        return this.chatMessages;
    }

    onAllParticipants(participants){
        this.participants = [];
        for(let participant of participants){
            this.addParticipantAccording(participant)
        }
        this.eventEmitter.emit("onParticipantUpdated",this.participants)
        return this.participants;
    }

    onNewParticipantAdded(participant){
        if(this.isParticipantAlreadyPresent(participant)){
            return;
        }
        this.addParticipantAccording(participant)
        this.eventEmitter.emit("onParticipantUpdated",this.participants)
        return this.participants;

    }

    addParticipantAccording(participant){
        if(participant.userId ===  this.getMeetingRequest().getUserId){
            this.participants.splice(0,0,participant);
        }
        else{
            this.participants.push(participant);
        }
    }

    isParticipantAlreadyPresent(newParticipant){
        for(let participant of this.participants){
            if(newParticipant.userId === participant.userId){
                return true;
            }
        }
        return false;
    }
    onParticipantLeft(participant){
        let userId = participant.userId
        for(var index = 0; index < this.participants.length; index ++){
            let oldParticipant = this.participants[index]
            if(userId === oldParticipant.userId){
                this.participants.splice(index,1)
            }
        }
        this.eventEmitter.emit("onParticipantUpdated",this.participants)
        return this.participants;
    }

    participantByUserId(userId){
        for(var index = 0; index < this.participants.length; index ++){
            let oldParticipant = this.participants[index]
            if(userId === oldParticipant["userId"]){
                return oldParticipant;
            }
        }
        return null;
    }
    
    getMeetingHandler(){
        return meetingHandler;
    }

    getMeetingRequest(){
        // console.log("getMeetingRequest")

        if(this.meetingRequest == null){
            var roomId = null;
            roomId = Utility.getRoomIdFromUrl()
            
            if(roomId == null){
                roomId = this.getMeetingHandler().randomRoomId();
            }
            var userId = Utility.getUserIdFromUrl() ;
            // userId = "123";
            this.meetingRequest = this.getMeetingHandler().meetingStartRequestObject(roomId,userId , Utility.getAppId())
            this.meetingRequest.isAdmin = Utility.isAdmin();
            this.meetingRequest.userData = Utility.getUserDataFromUrl();
            this.meetingRequest.apiData = Config.apiData(this.meetingRequest.appId,this.meetingRequest.isAdmin);
            this.meetingRequest.defaultWhiteboardEditEnable = Config.defaultWhiteboardEditEnable(this.meetingRequest.appId,this.meetingRequest.isAdmin);

            this.meetingRequest.numberOfUsers = Config.getNumberOfUserAccordingToApp(this.meetingRequest.appId);
            console.log("getMeetingRequest")
            // this.meetingRequest.defaultMicEnable = false;

          }
          return this.meetingRequest;
    }

    updateUserDataForRequest(key,data){
        var userData = this.getMeetingRequest().getUserData;
        if(userData === null){
            userData = {};
        }
        userData[key]  = data;
        this.getMeetingRequest().userData = userData;
    }
    // onVideoPausUnpauseDataUpdated(data){
    //     this.videoPauseResumeData[data["userId"]] = data["status"]
    // }
    // onAudioPauseUnpauseDataUpdted(data){
    //     this.audioPauseResumeData[data["userId"]] = data["status"]

    // }
    emitLocalEvent(event,data){
        this.eventEmitter.emit(event,data)

    }

    subscribeToLocalEvent(event ,listner){
        this.eventEmitter.on(event,listner);      

    }

    unSubscribeToLocalEvent(event,listner){
        this.eventEmitter.off(event,listner);
    }

    static  meetingHandler = new MeetingHandler();
    static getInstance(){
        return this.meetingHandler;
    }
}

export default MeetingHandler