import Utility from "./Utility";

const queryString = require("query-string");

class Config {
  static getNumberOfUserAccordingToApp(app) {
    if (app === "xpertflix") {
      return 2;
    }
    if (app === "dentalchat" || app === "teledental") {
      return 2;
    }

    return 12;
  }

  static byDefaultMessageOpen(app) {
    if (app === "xpertflix") {
      return false;
    }
    return true;
  }
  static getPowredByLink(app) {
    if (app === "xpertflix") {
      return "https://www.xpertflix.com/";
    }

    if (app === "uhc" || app === "yqgpros") {
      return "https://www.yqgtech.com/";
    }
    if (app === "teledental") {
      return "https://teledental.com/";
    } 
    if (app === "dentalchat") {
      return "https://dentalchat.com/";
    } 
    else {
      return "https://vanimeetings.com/";
    }
  }

  static getPowredByName(app) {
    if (app === "xpertflix") {
      return "Xpertflix";
    }

    if (app === "uhc" || app === "yqgpros") {
      return "YQG Tech";
    }
    if (app === "dentalchat") {
      return "Dental Chat";
    } 
    if (app === "teledental") {
      return "Tele Dental";
    } 
    else {
      return "Vani Meetings";
    }
  }

  static isRecordinRequired(appId, isAdmin) {
    if (appId === "yqgpros") {
      return false;
    }
    if (appId === "dentalchat" || appId === "teledental") {
      return false;
    }
    if (appId === "demo") {
      return false;
    }
    if (appId === "xpertflix") {
      return false;
    }
    return true;
  }
  static getGAId(appId) {
    if (appId === "xpertflix") {
      return "UA-190458994-6";
    }
    if (appId === "yqgpros" || appId === "uhc") {
      return "UA-190458994-5";
    }
    if (appId === "demo" || appId === "thinkmerit") {
      return "UA-190458994-4";
    }
    return "UA-190458994-4";
  }
  static isCopyLinkRequired(appId, isAdmin) {
    if (
      appId === "demo" ||
      appId === "trail" ||
      appId === "testing" ||
      appId === "thinkmerit"
    ) {
      return true;
    }
    if (appId === "yqgpros" || appId === "uhc") {
      return true;
    }
    return false;
  }
  static isWhiteboardRequired(appId, isAdmin) {
    if (appId === "xpertflix") {
      return false;
    }
    if (appId === "demo") {
      return true;
    }
    if (appId === "stx" && isAdmin) {
      return true;
    }
    if (appId === "yqgpros") {
      return true;
    }
    if (appId === "dentalchat" || appId === "teledental") {
      return false;
    }
    return true;
  }
  static whiteboardBaseUrl(appId) {
    if (appId === "yqgpros" || appId === "uhc") {
      return "https://whiteboard.yqgtech.com/";
    }
    return "https://whiteboard.vanimeetings.com/";
  }

  static isScreenshareRequired(appId, isAdmin) {
    if (appId === "demo" || appId === "thinkmerit") {
      return true;
    }
    if (appId === "yqgpros") {
      return true;
    }
    return true;
  }

  static getThankYouPage(appId, isAdmin) {
    if (appId === "demo") {
      return "https://vanimeetings.com/contact-us.html";
    } else if (appId === "stx" || appId === "thinkmerit") {
      return "https://thinkmerit.in/contact";
    } else if (appId === "yqgpros" || appId === "uhc") {
      return "https://www.yqgtech.com/contact-us.html";
    }
    if (appId === "teledental") {
      return "https://teledental.com/";
    }
    if (appId === "dentalchat") {
      return "https://dentalchat.com/";
    }
    return "https://vanimeetings.com/contact-us.html";
  }

  static errorMsgOnPermissionDenied(appId, isAdmin) {
    if (appId === "demo" || appId === "thinkmerit") {
      return "This feature is not allowed by admin";
    } else if (appId === "stx") {
      return "This feature is not allowed by teacher";
    }
    return "This feature is not allowed by admin";
  }

  static startMeetingButtonTitleWithoutRoomId(appId, isAdmin) {
    if (appId === "demo" || appId === "thinkmerit") {
      return "Start Meeting";
    } else if (appId === "stx") {
      return "Join Class";
    }
    return "Start Meeting";
  }

  static startMeetingButtonTitleWithRoomId(appId, isAdmin) {
    if (appId === "demo" || appId === "thinkmerit") {
      return "Join Meeting";
    } else if (appId === "stx") {
      return "Join Class";
    }
    return "Join Meeting";
  }

  static apiData(appId, isAdmin) {
    if (appId === "stx") {
      const queryParmas = queryString.parse(window.location.search);
      var userId = null;
      if (queryParmas.token !== null) {
        return { token: queryParmas.token };
      }
    } else if (appId === "dentalchat" || appId === "teledental") {
      var apiData = {};
      const queryParmas = queryString.parse(window.location.search);
      if (queryParmas.token !== null) {
        if (queryParmas.token && queryParmas.token != null) {
          let tokenData = Utility.decodeJWTToken(queryParmas.token);
          if (tokenData !== null && tokenData.hasOwnProperty("model")) {
            apiData.model = tokenData["model"];
            apiData.meetingId = tokenData["meetingId"];
            apiData.userId = tokenData["userId"];
          }
        }
      }

      return apiData;
    }
    return {};
  }

  static canEditName(appId, isAdmin) {
    if (appId === "stx") {
      return false;
    }
    if (appId === "dentalchat" || appId === "teledental") {
      return false;
    }
    return true;
  }
  static defaultWhiteboardEditEnable(appId, isAdmin) {
    if (appId === "stx") {
      if (isAdmin) {
        return true;
      } else {
        return false;
      }
    }
    if (isAdmin) {
      return true;
    } else {
      return true;
    }
    // return true;
  }
  static defualtCameraOn(appId, isAdmin) {
    if (appId === "stx") {
      if (isAdmin) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
  static defaultMicOn(appId, isAdmin) {
    if (appId === "stx") {
      if (isAdmin) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  static getCopyLinkMsg(appId, isAdmin) {
    if (appId === "stx") {
      return "";
    } else if (appId === "thinkmerit") {
      return " has invited you to join on Think Merit Call.\nClick on this link - ";
    } else if (appId === "yqgpros" || appId === "uhc") {
      return " has invited you to join on YQG Tech Call.\nClick on this link - ";
    }
    return " has invited you to join on Vani Meetings Call.\nClick on this link - ";
  }

  static canShowAdvanceOptions(appId) {
    if (appId === "stx") {
      return false;
    }
    if (appId === "dentalchat"  || appId === "teledental") {
      return false;
    }
    return true;
  }

  static shouldFixAdminVideoOnTop(appId, isAdmin) {
    if (appId === "stx" && isAdmin) {
      return true;
    }
    return false;
  }
  static getS3CredentialByName(app,dirName) {
    if (app === "dentalchat") {
      return {
        bucketName: "telemed-webrtc",
        region: "us-west-1",
        dirName:`dentalchat/${dirName}`,
        accessKeyId: "AKIAV3W4ZCYARGYCNGBX",
        secretAccessKey: "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",
      };
    } else if(app === "teledental"){
      return {
        bucketName: "telemed-webrtc",
        region: "us-west-1",
        dirName:`teledental/${dirName}`,
        accessKeyId: "AKIAV3W4ZCYARGYCNGBX",
        secretAccessKey: "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",
      };
    }
    else {
      return {};
    }
  }

}




export default Config;
