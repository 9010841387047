
import { isMobileOnly } from "react-device-detect";
import  MeetingBase  from "../../MeetingBase";
import MeetingHandler from "../../MeetingHandler";
import GalleryHolder from "./GalleryHolder";
import VideoHolder from "./VideoHolder";

class LeftPanel extends MeetingBase{
  
  constructor(){
    super()
    this.onTapSwitch = this.onTapSwitch.bind(this)
    this.state = {
      showVideo : true ,
    }
  }

  componentDidMount(){
   MeetingHandler.getInstance().subscribeToLocalEvent("onTapSwitch" , this.onTapSwitch)
  }

  onTapSwitch(data){
    if(data.isVideoTab){
      this.setState({showVideo: true})
     }
     else{
      this.setState({showVideo: false})
     }
  }

  componentWillUnmount(){
    MeetingHandler.getInstance().unSubscribeToLocalEvent("onTapSwitch",this.onTapSwitch)
  }
  
  
  render()
  {
    return <div className="col-md-12" style = {{display:`${this.state.showVideo || !isMobileOnly ? 'block' : 'none'}`}}>
     <VideoHolder/>  
     {/* <GalleryHolder /> */}
  </div>
  


}
}

export default LeftPanel;