import  MeetingBase  from "../../MeetingBase";
import React from 'react';
import MeetingHandler from "../../MeetingHandler";


class AudioStream extends MeetingBase{
  constructor(){
    super();
    this.audioPlayer = React.createRef();
    this.onSpeakerOff = this.onSpeakerOff.bind(this)
    this.onSpeakerOn = this.onSpeakerOn.bind(this)
    this.refershTrack = this.refershTrack.bind(this);

    this.isInPlayProcess = false;
    this.currentStream = null;

  }
  componentDidMount(){
    let audioStrem = this.props.audioStream;
    if(audioStrem != null && this.audioPlayer.current !== null){
        let track = audioStrem.track;
        if(track != null){
         
            let mediStream = new MediaStream();
            mediStream.addTrack(track);
            this.audioPlayer.current.load();

            this.audioPlayer.current.srcObject = mediStream;

            this.audioPlayer.current.play();
            this.audioPlayer.current.muted = audioStrem.extraData.isSpeakerOff
        }
    }

    MeetingHandler.getInstance().subscribeToLocalEvent("onSpeakerOn",this.onSpeakerOn)
    MeetingHandler.getInstance().subscribeToLocalEvent("onSpeakerOff",this.onSpeakerOff)
    super.addExtraListenerWithForcefullyAdded('refershTrack', this.refershTrack, true)

  }

  componentWillUnmount(){

    MeetingHandler.getInstance().unSubscribeToLocalEvent("onSpeakerOn",this.onSpeakerOn)
    MeetingHandler.getInstance().unSubscribeToLocalEvent("onSpeakerOff",this.onSpeakerOff)
    MeetingHandler.getInstance().unSubscribeToLocalEvent("refershTrack",this.refershTrack)

  }

  refershTrack(data){
    if(data !== null && data.trackId === this.props.audioStream.trackId && this.audioPlayer.current !== null){
      let track = data.track;
      if(track != null){
        
          let mediStream = new MediaStream();
          mediStream.addTrack(track);

          // this.audioPlayer.current.srcObject = mediStream;

          // this.audioPlayer.current.play();

          this.playMediaStream(mediStream)
          this.audioPlayer.current.muted = this.props.audioStream.extraData.isSpeakerOff
      }
    }
  }



  playMediaStream(mediStream){
    if(this.isInPlayProcess ){
      this.currentStream = mediStream;
      return;
    }  
    this.audioPlayer.current.onplay = function(){
      this.isInPlayProcess = false;
      if(this.currentStream  !== null){
          this.playMediaStream(this.currentStream)
      }
    }.bind(this)


    this.audioPlayer.current.srcObject = mediStream;
    this.audioPlayer.current.play();
    this.isInPlayProcess = true;
    this.currentStream = null;

  }
  
  onSpeakerOff(){
    this.props.audioStream.extraData.isSpeakerOff = true;
    this.audioPlayer.current.muted = true;

  }


  onSpeakerOn(){
    this.props.audioStream.extraData.isSpeakerOff = false;
    this.audioPlayer.current.muted = false;

  }


  render(){
  
    return      <audio  key={this.props.audioStream.participant.userId} playsInline  autostart="true" ref={this.audioPlayer} ></audio> 


}
}



export default AudioStream;