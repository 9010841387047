
import  MeetingBase  from "../MeetingBase";

class Disconnect extends MeetingBase{
  
  componentDidMount(){
   
  }
  
  
  render()
  {
  return <div>
      Reconnecting
      </div>

}
}

export default Disconnect;