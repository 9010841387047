import  MeetingBase  from "../../MeetingBase";
import React from 'react';

import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import Utility from "../../extra/Utility";
import { Modal,Button } from 'react-bootstrap';
import Config from "../../extra/Config";
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';

class Participant extends MeetingBase{

  constructor(props){
    super(props)


    this.messageBlockUnblockCheckBox = React.createRef();
    this.videoBlockUnblockCheckBox = React.createRef();
    this.audioBlockUnblockCheckBox = React.createRef();
    this.whiteboardBlockUnblockCheckBox = React.createRef();

    
    this.name = "Unknown"
    this.userId = null;
    if(props.participant.userData != null && props.participant.userData.hasOwnProperty("name") ){
          this.name = props.participant.userData.name 
    }
    if(this.name == null || this.name.length === 0){
        this.name = "Unknown"
    }
    if(props.participant.userId != null){
      this.userId = props.participant.userId;
      if(this.userId === super.getMeetingRequest().getUserId){
        this.name =  this.name + " (Me)"
      }
      
    }

    this.initial = this.name.charAt(0)
    this.participant = props.participant

    this.state = {videoPaused : !props.participant.isVideoEnable, audioPaused : !props.participant.isAudioEnable 
      , showAdminOptions : false , isMicBlocked : props.participant.isAudioBlockedByAdmin , isWhiteboardBlocked : props.participant.isWhiteboardBlockedByAdmin }

    this.onAudioVideoStatusUpdated = this.onAudioVideoStatusUpdated.bind(this);
    this.handleAdminOptions = this.handleAdminOptions.bind(this)
    this.onBlockUnBlockMicTapped = this.onBlockUnBlockMicTapped.bind(this);
    this.hideAdminOptions = this.hideAdminOptions.bind(this);
    this.onSaveChanges = this.onSaveChanges.bind(this);
  }
  
  componentDidMount(){
    super.addExtraListenerWithForcefullyAdded('audioVideoStatusUpdated', this.onAudioVideoStatusUpdated,true)
  
  }

  componentWillUnmount(){
    super.removeExtraListener('audioVideoStatusUpdated', this.onAudioVideoStatusUpdated)
  }

  onAudioVideoStatusUpdated(participant){
    if(participant.userId === this.userId){
      let isAudioEnable = (participant.isAudioEnable && !participant.isAudioBlockedByAdmin)
      if(this.state.audioPaused !== (!isAudioEnable)){
        this.setState({audioPaused : !isAudioEnable})
      }

      if(this.state.videoPaused !== (!participant.isVideoEnable)){
        this.setState({videoPaused : !participant.isVideoEnable})
      }
    }


  }
  

  handleAdminOptions(){
    this.setState({showAdminOptions : true})

    
  }

  hideAdminOptions(){
    this.setState({showAdminOptions : false})

    
  }

  onSaveChanges(){

    if(this.messageBlockUnblockCheckBox.current.checked !== (!this.participant.isMessageBlockedByAdmin)){
      this.onBlockUnblockMsgTapped();
    }


    if(this.audioBlockUnblockCheckBox.current.checked !== (!this.participant.isAudioBlockedByAdmin)){
      this.onBlockUnBlockMicTapped();
    }

    if(this.videoBlockUnblockCheckBox.current.checked !== (!this.participant.isVideoBlockedByAdmin)){
      this.onBlockUnBlockVideoTapped();
    }

    if(this.whiteboardBlockUnblockCheckBox !== null && this.whiteboardBlockUnblockCheckBox.current !== null &&this.whiteboardBlockUnblockCheckBox.current.checked !== (!this.participant.isWhiteboardBlockedByAdmin)){
      this.onBlockUnBlockWhiteboardTapped();
    }
    
    this.hideAdminOptions();
  }

  onBlockUnblockMsgTapped(){
    var output = null;
    if(this.participant.isMessageBlockedByAdmin){
      output = super.getMeetingHandler().unblockMessaging(this.participant.userId)
    }
    else{
      output = super.getMeetingHandler().blockMessaging(this.participant.userId)
    }

    if(output !== null){
      if(output.isSuccess){
        this.setState({isMessagingBlocked : this.participant.isMessageBlockedByAdmin})
      }
      else{
        Utility.showAlertOnOutputError(output,super.getMeetingRequest().getUserId)
      }
    }
  }

  onBlockUnBlockVideoTapped(){
    if(this.participant.isVideoBlockedByAdmin){
      super.getMeetingHandler().resumeCamera(this.participant.userId).then(

        function(output) {
          console.log(output)
          this.handleOutput(output,false,true)}.bind(this),
        function(error) {}
      );
    }
    else{
      super.getMeetingHandler().pauseCamera(this.participant.userId).then(

        function(output) {
          console.log(output)
          this.handleOutput(output,false,true)}.bind(this),
        function(error) {}
      );
    }

    
  }


  onBlockUnBlockMicTapped(){
    if(this.participant.isAudioBlockedByAdmin){
       super.getMeetingHandler().unmute(this.participant.userId).then(

        function(output) {
          console.log(output)
          this.handleOutput(output,true,false)}.bind(this),
        function(error) {}
      );
    }
    else{
      super.getMeetingHandler().muteUser(this.participant.userId).then(

        function(output) {
          console.log(output)
          this.handleOutput(output,true,false)}.bind(this),
        function(error) {}
      );
    }

    
  }
  handleOutput(output,isForAudio,isForVideo){
    if(output !== null){

      if(output.isSuccess){
        if(isForAudio){
          this.setState({isMicBlocked : this.participant.isAudioBlockedByAdmin})
        }
        
        if(isForVideo){
          this.setState({isVideoBlocked : this.participant.isVideoBlockedByAdmin})

        }
      }
      else{
        Utility.showAlertOnOutputError(output,super.getMeetingRequest().getUserId)
      }
    }
  }
  

  onBlockUnBlockWhiteboardTapped(){
    var output = null;
    if(this.participant.isWhiteboardBlockedByAdmin){
      output = super.getMeetingHandler().unblockWhiteboard(this.participant.userId)
    }
    else{
      output = super.getMeetingHandler().blockWhiteboard(this.participant.userId)
    }

    if(output !== null){
      if(output.isSuccess){
        this.setState({isWhiteboardBlocked : this.participant.isWhiteboardBlockedByAdmin})
      }
      else{
        Utility.showAlertOnOutputError(output,super.getMeetingRequest().getUserId)
      }
    }
  }

  
  
  render()
  {
    return <div style = {{marginBottom:'15px'}}>
      <div className="particbox d-flex justify-content-between">	    	
    <div className="chattext d-flex" style = {{display:'flex' , justifyContent:"center" , alignItems:'center' , flexDirection:'row'}}>
      
      <div className="inital" style = {{height:'20px' , width:'20px' , lineHeight:'20px'}}>
      { this.initial }
      </div> 
      <div className="partcont dropdown ">
        <h5 style= {{fontSize:'14px'}}>{this.name}</h5>
        {/* <div className="dropdown-menu">
          <a className="dropdown-item" href="#" data-toggle="modal" data-target="#myModal">Rename</a>
        </div> */}
      </div>
    </div>	
    <div className="vdmutestn">
      <ul>
        {/* <li><a><SignalWifiOffIcon/></a></li> */}
        <li><a ><i className={this.state.audioPaused ? "fa fa-microphone-slash " : "fa fa-microphone "} /></a></li>
        <li><a>
            {this.state.videoPaused ? <VideocamOffIcon/>:<VideocamIcon/>}
        </a></li>
        {(super.getMeetingRequest().isAdmin && super.getMeetingRequest().getUserId !== this.userId) ?
        <button style = {{outline:'none' , border:'none' ,background:'transparent'}} onClick = {this.handleAdminOptions} ><a style = {{fontSize:'14px' , fontWeight:'bold'}}>
            ...
        </a></button>  : null}
      </ul>
    </div>
  </div>
  {/* <div className ={`admin_options ${this.state.showAdminOptions && 'showAdminOptions'}`}>
    <div onClick={this.onBlockUnBlockMicTapped} className = 'adminOption'>{this.state.isMicBlocked ? "Unblock Mic" : "Block Mic"}</div>
    <div className = 'adminOption'>Stop Video</div>
    <div className = 'adminOption'>Stop Messaging</div>
    <div className = 'adminOption'>Disallow whiteboard use</div>
  </div> */}


{this.state.showAdminOptions ?
  <Modal show={true}  size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={this.hideAdminOptions}>
        <Modal.Header closeButton>
          <Modal.Title>{this.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="vanicall">
          <div className="Vdchat">
            <div className="chckbx">
              <div className="form-check">
                <label className="form-check-label">
                  <input type="checkbox" className="form-check-input"   ref={this.audioBlockUnblockCheckBox}   defaultChecked = {!this.participant.isAudioBlockedByAdmin} /> <span>Mic</span>
                </label>
              </div>
            </div>
          </div>
          <div className="Vdchat">
            <div className="chckbx">
              <div className="form-check">
                <label className="form-check-label">
                  <input type="checkbox" className="form-check-input" ref={this.videoBlockUnblockCheckBox}  defaultChecked = {!this.participant.isVideoBlockedByAdmin} /> <span>Video</span>
                </label>
              </div>
            </div>
          </div>
          <div className="Vdchat">
            <div className="chckbx">
              <div className="form-check">
                <label className="form-check-label">
                  <input type="checkbox" className="form-check-input"  ref={this.messageBlockUnblockCheckBox}  defaultChecked = {!this.participant.isMessageBlockedByAdmin} /> <span>Messaging</span>
                </label>
              </div>
            </div>
          </div>
          {Config.isWhiteboardRequired(Utility.getAppId(),super.getMeetingRequest().isAdmin) ?
          <div className="Vdchat">
          <div className="chckbx">
            <div className="form-check">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input"  ref={this.whiteboardBlockUnblockCheckBox}  defaultChecked = {!this.participant.isWhiteboardBlockedByAdmin} /> <span>WhiteBoard</span>
              </label>
            </div>
          </div>
        </div> : null}
          
        </div>	

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.hideAdminOptions}>
            Close
          </Button>
          <Button variant="primary" onClick={this.onSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
   : null}

  
    </div>
  
  
  


}
}

export default Participant;