import  MeetingBase  from "../MeetingBase";

class PermissionError extends MeetingBase{
 
   
    render(){
      return <div className="permision d-flex justify-content-around mt-3">
      <div className="persn">
        <i className="fa fa-info-circle" aria-hidden="true" /> <span> Permission<br /> Denied</span>
      </div>
      <div className="prcont">
        <p>We could not get permission to access your device. Click the lock icon in the top left to change permissions.</p>
      </div>
    </div>
     

   
  
}

}

export default PermissionError;