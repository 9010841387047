import MeetingBase from "../../MeetingBase";
import SingleChatMessage from "./SingleChatMessage";
import React from "react";
import MeetingHandler from "../../MeetingHandler";
import Utility from "../../extra/Utility";
import S3 from "react-aws-s3";
import Config from "../../extra/Config";
class ChatPanel extends MeetingBase {
  constructor() {
    super();

    let messages = MeetingHandler.getInstance().getChatMessages();
    this.state = { messages: messages, file: null, loading: false };
    this.onSendMessageTapped = this.onSendMessageTapped.bind(this);
    this.addNewMessage = this.addNewMessage.bind(this);
    this.onInputTextKeyDown = this.onInputTextKeyDown.bind(this);
    this.messagesEndRef = React.createRef();
    this.messageInput = React.createRef();
    this.roomId = MeetingHandler.getInstance().getMeetingRequest().roomId;
    this.s3config = {
      ...Config.getS3CredentialByName(Utility.getAppId(), this.roomId),
    };
    this.s3Client = new S3(this.s3config);
  }

  componentDidMount() {
    this.scrollToBottom();
  }
  componentDidUpdate() {
    this.scrollToBottom();
  }

  onInputTextKeyDown(e) {
    if (e.key === "Enter") {
      this.onSendMessageTapped();
    }
  }

  onSendMessageTapped() {
    var messageInputValue = this.messageInput.current.value;
    if (messageInputValue != null && messageInputValue.length > 0) {
      let messageObject = super
        .getMeetingHandler()
        .newMessageObject("all", this.messageInput.current.value);
        console.log(messageObject);
      let output = super.getMeetingHandler().sendMessage(messageObject);
      if (output !== null) {
        if (output.isSuccess) {
          this.addNewMessage(messageObject);
        } else {
          Utility.showAlertOnOutputError(
            output,
            super.getMeetingRequest().getUserId
          );
        }
      }
      this.messageInput.current.value = "";
    }
  }

  onFileUpload = (e) => {
    if (e.target.files.length) {
      if (e.target.files[0].size / 1024 / 1024 > 15) {
        console.log("file size exceeded");
        return;
      } else {
        this.setState({ loading: true });
        this.s3Client
          .uploadFile(e.target.files[0], e.target.files[0].name)
          .then((res) => {
            const file = res.location;
            let msgObj = super
              .getMeetingHandler()
              .newMessageObject("all", encodeURI(file));
            msgObj.type = "file";

            let result = super.getMeetingHandler().sendMessage(msgObj);

            if (result.isSuccess) {
              this.setState({ loading: false });
              this.addNewMessage(msgObj);
            } else {
              Utility.showAlertOnOutputError(
                result,
                super.getMeetingRequest().getUserId
              );
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.log("something went wrong");
            console.log(err);
          });
      }
    } else {
      console.log("caceled");
      return;
    }
  };

  addNewMessage(message) {
    let allMessages = MeetingHandler.getInstance().addNewChatMessage(message);
    this.setState({ messages: allMessages });
  }

  scrollToBottom = () => {
    if (this.props.shouldShowMessages) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    console.log(this.state.file);
    return (
      <div
        id="home"
        className="tab-pane active"
        style={{ overflowY: "scroll", overflowX: "hidden", height: "55vh" }}
      >
        <br />
        {this.state.messages.map((message, index) => (
          <SingleChatMessage key={index} message={message} loading={false} />
        ))}
        {this.state.loading && (
          <SingleChatMessage message={""} loading={true} />
        )}
        <div ref={this.messagesEndRef} className="scrolltobottom"></div>
        <div className="semdmsg mt-4">
          <div className="input_box">
            <label className="file_label">
              <input
                type="file"
                className="file_input"
                onChange={(e) => this.onFileUpload(e)}
              />
              <i className="fa fa-paperclip attachIcon" aria-hidden="true" />
            </label>
            <div className="input_group">
              <input
                onKeyDown={this.onInputTextKeyDown}
                ref={this.messageInput}
                type="text"
                className="input_append"
                placeholder="Write Your Message"
                style={{}}
              />
              <button
                onClick={this.onSendMessageTapped}
                className="send_btn"
                type="submit"
              >
                <i className="fa fa-paper-plane" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatPanel;
