import  MeetingBase  from "../MeetingBase";
import React from 'react';
import MeetingHandler from "../MeetingHandler";
import Utility from "../extra/Utility";
import Config from "../extra/Config";
import { Modal,Button } from 'react-bootstrap';

class InformationForm extends MeetingBase{

    
    constructor(){
      super();

      this.adminCheckBox = React.createRef();

      this.name = null;
      this.storedName = null;

      let meetingRequest = super.getMeetingRequest();
      if(meetingRequest !== null && meetingRequest.userData !== null && meetingRequest.userData.hasOwnProperty("name")){
        this.storedName = this.name = meetingRequest.userData["name"];
      }

      if(this.name  === null){
        this.storedName = localStorage.getItem("userName")
        if(this.storedName !== null && this.storedName.length > 0){
          this.name = this.storedName;
        }
        if( this.name === null)
        {
          const sampleName = ["Akshay sood", "Mark Sims" , "Kevin shaw" , "Himanshu Gupta" , "Jonathan Trott" ,  "Navdeep Kaur"]
          this.name = sampleName[Math.floor(Math.random() * sampleName.length)];
    
        }
      }
      this.showAdvanceOptions = this.showAdvanceOptions.bind(this);
      
      this.onStartMeetingTapped = this.onStartMeetingTapped.bind(this);
      this.onPermissionError = this.onPermissionError.bind(this);
      this.hideAdvanceOptions = this.hideAdvanceOptions.bind(this);
      this.onSaveChanges = this.onSaveChanges.bind(this);
      this.userName = React.createRef();

      this.state = {startButtonText : 
        Config.startMeetingButtonTitleWithoutRoomId(super.getMeetingRequest().appId ,super.getMeetingRequest().isAdmin  )
        , loader : false , advanceOptionsVisible : false} 

        this.canEditName = Config.canEditName(meetingRequest.appId , meetingRequest.isAdmin)

    }

    componentDidMount(){

     
      let roomId = Utility.getRoomIdFromUrl()
      if(roomId !== null){
        this.setState({startButtonText :         Config.startMeetingButtonTitleWithRoomId(super.getMeetingRequest().appId ,super.getMeetingRequest().isAdmin  )
        })
      }

      if(this.storedName !== null && this.storedName.length > 0){
        this.userName.current.value = this.storedName;
      }
    }
    componentWillUnmount(){

    }

    onPermissionError(){

    }
    showAdvanceOptions(){
      this.setState({advanceOptionsVisible : true})
    }
    hideAdvanceOptions(){
      this.setState({advanceOptionsVisible : false})

    }
    onSaveChanges(){
      let meetingRequest = super.getMeetingRequest();
      meetingRequest.isAdmin = this.adminCheckBox.current.checked
      meetingRequest.defaultWhiteboardEditEnable = Config.defaultWhiteboardEditEnable(meetingRequest.appId,meetingRequest.isAdmin);

      this.hideAdvanceOptions()
    }
    onStartMeetingTapped(){
      
      console.log("User Id : " + super.getMeetingRequest().getUserId)
      if(MeetingHandler.getInstance().getMeetingHandler().isPermissionApproved()){
        var userInputName = this.userName.current.value;
        if(userInputName != null && userInputName.length > 0){
          
          this.name = userInputName;
          localStorage.setItem("userName",this.name)

        }
        MeetingHandler.getInstance().updateUserDataForRequest("name",this.name)
        super.getMeetingHandler().checkSocket();
        Utility.logEvent({
          category: 'Room Id : ' + super.getMeetingRequest().getRoomId,
          action: 'User Id : ' + super.getMeetingRequest().getUserId,
          value: super.getMeetingRequest().getUserData
        });
        this.setState({loader : true})
      }
      else{
        alert("Please allow permission to continue.");
      }
     
    }
    render(){
    return  <div className="col-md-6" style = {{position:'relative' , width:'100%' }}>
      {this.state.loader ?   <div className="loading">
        <img  src="/loader.svg" />
      </div> : null}
      
    <div className="loginbox shadow p-4">
      {/* <form> */}
        <div className="metinfield">
          <div className="form-group">
            <label htmlFor="usr">Your Name</label>
            <input ref={this.userName}  type="text" className="form-control" id="usrName" placeholder={this.name}
                readOnly = {!this.canEditName}
            />
          </div>
          {/* <div className="form-group">
            <label htmlFor="usr">Meeting Name</label>
            <input type="text" className="form-control" id="mname" placeholder="Meeting name" />
          </div> */}
          <button type="submit" className="btn btn-primary w-100" onClick={this.onStartMeetingTapped}>{this.state.startButtonText}</button>

          {Config.canShowAdvanceOptions(super.getMeetingRequest().appId) ? <div className="advanced d-flex px-3 mt-4" onClick={this.showAdvanceOptions}>
            <div className="advicon">
              <i className="fa fa-cog" aria-hidden="true" />
            </div>
            <div className="adcnts">
              <h5>Advanced</h5>
              <p>Manage host controls and settings</p>
            </div>
          </div> : null}


          {this.state.advanceOptionsVisible ?
  <Modal show={true}  size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={this.hideAdvanceOptions}>
        <Modal.Header closeButton>
          <Modal.Title>Advance Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="vanicall">
          <div className="Vdchat">
            <div className="chckbx">
              <div className="form-check">
                <label className="form-check-label">
                  <input type="checkbox" className="form-check-input"   ref={this.adminCheckBox} defaultChecked = {super.getMeetingRequest().isAdmin}   /> <span>Admin Role</span>
                </label>
              </div>
            </div>
          </div>
          
          
          
        </div>	

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.hideAdvanceOptions}>
            Close
          </Button>
          <Button variant="primary" onClick={this.onSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
   : null}
          
        </div>	
        
      {/* </form> */}
    </div>
  </div>
}
}

export default InformationForm