import React from "react";
import "./notSupported.css";
import { isAndroid } from "react-device-detect";
import Logo from "../../logoicon.png";
const queryString = require("query-string");

const NotSupported = () => {
  var intent = null;
  if (isAndroid) {
    const queryParmas = queryString.parse(window.location.search);
    var appId = null;
    if (queryParmas.vm && queryParmas.vm !== null) {
      appId = queryParmas.vm;
    }
    var url = window.location.origin;
    url = url + "?vm=" + appId;
    console.log(url);

    url = url.replace("https", "");
    intent =
      "intent" +
      url +
      "#Intent;scheme=" +
      "https" +
      ";package=com.android.chrome;end;";
  }

  return (
    <div className="notsupported__container">
      <div className="notsupported__navbar">
        <div className="navbar__logo">
          <img src={Logo} />
        </div>
      </div>

      <div className="notsupported__main">
          <p className="notsupported__main__info">This Browser does not support screen sharing</p>
          {intent === null ? <p className="notsupported__main__todo">Please open the link in Chrome,Safari or Firefox</p> 
          :
          <a href={intent}>Tap here to open in chrome browser</a>}
        </div>
    </div>
  );
};

export default NotSupported;
