import { DropdownButton, Dropdown } from 'react-bootstrap';
import Utility from '../extra/Utility';
import MeetingBase from "../MeetingBase";
import PermissionError from "./PermissionError";

class CameraMicrophoneOptionSelector extends MeetingBase {
  constructor(props) {
    super(props);
    this.state = { audioIn: [], audioOut: [], camera: []   ,shouldShowErrorMsg  : false}
    
    this.onPermissionError = this.onPermissionError.bind(this);
    this.onPermissionApproved = this.onPermissionApproved.bind(this);

  }


  onPermissionApproved(data){
    this.setState({shouldShowErrorMsg : false})
    Utility.logEvent({
      category: 'Permission Approved',
      action: super.getMeetingRequest().getUserId
    });

    this.getAllDevices();

    
  }

  onPermissionError(data){
    this.setState({shouldShowErrorMsg : true})
    Utility.logEvent({
      category: 'Permission Error',
      action: data
    });
  }

  componentDidMount() {

    super.addExtraListener('permissionError',this.onPermissionError)
    super.addExtraListener('permissionApproved', this.onPermissionApproved)
  }

  componentWillUnmount(){
    super.removeExtraListener('permissionError',this.onPermissionError)
    super.removeExtraListener('permissionApproved', this.onPermissionApproved)
  }
  onMicSourceChange(eventKey ,event) {
    // console.log(eventKey);
    super.getMeetingRequest().audioInDevice = eventKey;
    super.getMeetingHandler().startLocalStream(false,true);
  }
  onSpeakerChange(eventKey ,event) {
    // console.log(eventKey);
    super.getMeetingRequest().audioOut = eventKey;
    // super.getMeetingHandler().init();

  }
  onCameraChange(eventKey ,event) {
    // console.log(eventKey);
    super.getMeetingRequest().cameraDevice = eventKey;
    super.getMeetingHandler().startLocalStream(true,false);
  }
  async getAllDevices() {
    let audioIn = await super.getMeetingHandler().getAudioInDevices();
    let audioOut = await super.getMeetingHandler().getAudioOutDevices();
    let camera = await super.getMeetingHandler().getCameraDevices();
    this.state = { audioIn: [], audioOut: [], speakerOut: [] }

    this.setState({ audioIn: audioIn, audioOut: audioOut, camera: camera });

  }

  render() {
    var audioInDropDownItems = []
    for (let audio of this.state.audioIn) {
      audioInDropDownItems.push(<Dropdown.Item key={audio.id} eventKey={audio.id} >{audio.label}</Dropdown.Item>);
    }
    var speakerDropDownItems = []
    for (let speaker of this.state.audioOut) {
      speakerDropDownItems.push(<Dropdown.Item key={speaker.id} eventKey={speaker.id} >{speaker.label}</Dropdown.Item>);
    }
    var cameraDropDownItems = []
    for (let camera of this.state.camera) {
      cameraDropDownItems.push(<Dropdown.Item key={camera.id} eventKey={camera.id} >{camera.label}</Dropdown.Item>);
    }

    if(this.state.shouldShowErrorMsg){
return <PermissionError/>
    }
    else{
    return <div className="d-flex justify-content-around mutvolm mt-3 mb-3" style = {{flexWrap:'wrap' , position:'relative'}}>
      <div className="mutemike" style ={{margin :'10px'}} >
        <div className="dropdown">
          <DropdownButton id="dropdown-basic-button" title="Microphone" onSelect={this.onMicSourceChange}>
            {audioInDropDownItems}
          </DropdownButton>
        </div>
      </div>
      <div className="volspekr" style ={{margin :'10px'}}>
        <div className="dropdown">
          <DropdownButton id="dropdown-basic-button" title="Speaker" onSelect = {this.onSpeakerChange}>
            {speakerDropDownItems}
          </DropdownButton>
        </div>
      </div>
      <div className="cameraoption" style ={{margin :'10px'}}>
        <div className="dropdown">
          <DropdownButton id="dropdown-basic-button" title="Camera" onSelect = {this.onCameraChange}>
            {cameraDropDownItems}
          </DropdownButton>
        </div>
      </div>
    </div>
    }
  }

  
}

export default CameraMicrophoneOptionSelector;